.venue-container {

  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly; // Align items to the start of the row
  align-items: stretch; // Ensure all venues stretch evenly
  background-color: #005a7b;

  .venue {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 1 auto; // Allows venues to shrink and grow as needed
    max-width: 300px; // Optional: Limit the maximum width
    margin: 8px;

    .image-container {
      width: 100%;
      height: auto; // Ensure dynamic scaling
      aspect-ratio: 4 / 3; // Maintain consistent aspect ratio
      position: relative;
      background-color: aliceblue; // Background for padding around logos
      padding: 0.25rem; // Space around the logo
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      img {
        position: absolute;
        top: 50%; // Center the image vertically
        left: 50%; // Center the image horizontally
        transform: translate(-50%, -50%); // Align image perfectly center
        max-width: 90%; // Scale down to fit within the container
        max-height: 90%; // Scale down to fit vertically within the container
        object-fit: contain; // Ensure entire image fits without distortion
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
