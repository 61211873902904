.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: url('https://api.gounasailingclub.com/images/boats/1726755045923.webp');
  background-size: cover; /* Ensures the image covers the container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  position: relative; /* Makes the container a positioned element */
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: color;


  .hero {
    // background-color: red;
    text-align: center;
    margin-bottom: 30px;

    h1 {
      font-size: 2.5rem;
      color: #00a6ff;
    }

    p {
      font-size: 1.2rem;
      color: #d8d8d8;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .boat-gallery {
    // background-color: black !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 90%;
    padding: 0 15px;
  }

  .boat-link {
    text-decoration: none;
    width: 100%;
    max-width: 300px;
  }

  .boat-card {
    background-color: #f7f7ff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    width: 100%;

    &:hover {
      transform: scale(1.05);
    }

    .boat-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .boat-info {
      padding: 15px;
      text-align: center;

      h3 {
        font-size: 1.5rem;
        color: #005c8e;
        margin-bottom: 5px;
      }

      p {
        font-size: 1rem;
        color: #333;
        margin: 5px 0;
      }
    }
  }

  .classes {
    // background-color: aquamarine;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    text-align: center;
    a{
      text-decoration: none;
    }

    h2 {
      font-size: 2rem;
      color: #00a6ff;

      margin-bottom: 10px;
    }

    .boat-card {
      margin-top: 15px;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .hero {
      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }
    }

    .boat-info h3 {
      font-size: 1.25rem;
    }

    .boat-info p {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    .hero {
      h1 {
        font-size: 1.8rem;
      }

      p {
        font-size: 0.9rem;
      }
    }

    .boat-info h3 {
      font-size: 1.1rem;
    }

    .boat-info p {
      font-size: 0.85rem;
    }
  }
}
