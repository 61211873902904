.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup-content {
    overflow-y: auto;
    background-color: #fff;
    padding: 2rem;
    width: 90%;
    height: 90%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  h1 {
    color: #0c5c34; /* Dartmouth Green */
    font-size: 1.8rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1rem;
    color: #71644c; /* Pantone color */
  }

  input,
  select {
    padding: 0.6rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    margin-left: 0.5rem;
  }

  input:focus,
  select:focus {
    outline: none;
    border-color: #56bab8ff; /* Verdigris */
  }

  .submit-button {
    background-color: #e98a2eff; /* Tangerine */
    color: #fff;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
  }

  .submit-button:hover {
    background-color: #de542fff; /* Cinnabar */
  }

  p {
    color: #de542fff; /* Cinnabar */
    font-size: 1rem;
    margin-top: 0;
  }

  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .popup-content {
      padding: 1.5rem;
    }

    h1 {
      font-size: 1.5rem;
    }

    input,
    select {
      font-size: 0.9rem;
    }

    .submit-button {
      padding: 0.6rem 1.2rem;
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 480px) {
    .popup-content {
      width: 95%;
      padding: 1rem;
    }

    h1 {
      font-size: 1.3rem;
    }

    input,
    select {
      font-size: 0.85rem;
    }

    .submit-button {
      padding: 0.5rem 1rem;
      font-size: 0.85rem;
    }
  }
}
