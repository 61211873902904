.footer {
  background-color: #021526;;
  color: #f4f4f4;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index:9;

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-evenly;
    }

    .footer-company,
    .footer-contact,
    .footer-developed {
      display: flex;
      align-items: center;
      gap: 10px;
      text-align: center;

      h3 {
        margin: 0;
        font-size: 1rem;
        color: #f4f4f4;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
      }

      .footer-logo,
      .developer-logo {
        width: 40px;
        height: auto;
      }

      .company-name,
      .developer-name {
        font-weight: bold;
        font-size: 1.1rem;
      }

      .developer-contact {
        // display: none;

        @media (min-width: 768px) {
          display: block;
          margin-left: 10px;

          p {
            font-size: 0.8rem;
            color: #bbb;
          }
        }
      }
    }
    .footer-developed,.footer-contact {
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  .footer-social {
    display: flex;
    gap: 15px;
    justify-content: center;

    .social-link {
      color: #f4f4f4;
      font-size: 1.2rem;
      transition: color 0.3s;

      &:hover {
        color: #ddd;
      }

      i {
        font-size: 1.5rem;
      }
    }
  }
}
