* {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;

  font-family: "Inria Sans", sans-serif;

  font-style: normal;
  font-optical-sizing: auto;
}

body {
  width: 100%;
  height: 100%;
}