.transaction-status {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    height: 85vh;
    background: url('https://api.gounasailingclub.com/images/boats/1726755045923.webp');
    background-size: cover; /* Ensures the image covers the container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    position: relative; /* Makes the container a positioned element */
    background-color: rgba(0, 0, 0, 0.65);
    background-blend-mode: color;
  
    .transaction-success, .transaction-fail {
      padding: 2rem;
      border-radius: 8px;
      max-width: 600px;
    }
  
    .transaction-success {
      background-color: #e0f7ea;
      color: #388e3c;
  
      h1 {
        color: #2e7d32;
      }
    }
  
    .transaction-fail {
      background-color: #ffebee;
      color: #d32f2f;
  
      h1 {
        color: #c62828;
      }
    }
  
    .home-link {
      display: inline-block;
      margin-top: 2rem;
      padding: 0.75rem 1.5rem;
      color: #ffffff;
      background-color: #007bff;
      border-radius: 4px;
      text-decoration: none;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  