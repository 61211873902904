.activity-container {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1019%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(241%2c 238%2c 219%2c 1)'%3e%3c/rect%3e%3cpath d='M 0%2c33 C 57.6%2c67.4 172.8%2c190 288%2c205 C 403.2%2c220 460.8%2c99.4 576%2c108 C 691.2%2c116.6 748.8%2c250.4 864%2c248 C 979.2%2c245.6 1036.8%2c99 1152%2c96 C 1267.2%2c93 1382.4%2c205.6 1440%2c233L1440 560L0 560z' fill='rgba(86%2c 186%2c 184%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c475 C 96%2c447.8 288%2c338 480%2c339 C 672%2c340 768%2c487.2 960%2c480 C 1152%2c472.8 1344%2c338.4 1440%2c303L1440 560L0 560z' fill='rgba(222%2c 84%2c 47%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1019'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // background: rgb(58,79,139);
  // background: radial-gradient(circle, rgba(58,79,139,1) 0%, rgba(25,34,60,1) 100%);
  .activity {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-bottom: 10px;
    transition: all 0.1s ease-out;
    &:hover {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.495);
    }
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgb(0, 16, 31);
    @media (max-width: 745px) {
      width: 100%;
    }

    .image-container {
      overflow: hidden;
      img {
        width: 100%;

        height: 200px;
        object-position: 50% 80%;

        object-fit: cover;
        transition: all 0.3s ease-out;

        &:hover {
          scale: 1.1;
        }
      }
    }
    h2 {
      margin: 0 auto;
    }
    p {
      color: #0c5c34ff;
      padding: 10px;
      margin: 0 auto;
    }
  }
}
